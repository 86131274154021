import clsx from 'clsx'
import { Button } from 'components/Button/Button'
import RadioButton from 'components/RadioButton/RadioButton'
import TextFieldDescribed from 'components/TextFieldDescribed/TextFieldDescribed'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { MISSION_ZONE_TYPES, PROGRESS_TYPES } from 'utils/constants'
import { IZone } from 'utils/interfaces'
import { useShallow } from 'zustand/react/shallow'

import LaunchPointSettings from './LaunchPointSettings/LaunchPointSettings'

import style from './ZoneDetails.module.css'

const ZoneDetail = ({
  onSaveClicked,
  zone,
}: {
  onSaveClicked: (zoneName: string, zoneDescription: string, zoneType: MISSION_ZONE_TYPES) => void
  zone?: IZone
}) => {
  const { tempZone, savingProgress, cleaningZones, cancelEditingZone } = useMissionStore(
    useShallow(state => ({
      tempZone: state.tempZone,
      savingProgress: state.savingProgress,
      cleaningZones: state.cleaningZones,
      cancelEditingZone: state.cancelEditingZone,
    }))
  )
  const { t } = useTranslation()
  const [selectedZoneType, setSelectedZoneType] = useState(zone?.properties?.zone_type || MISSION_ZONE_TYPES.CLEANING)
  const [name, setName] = useState(zone?.properties?.name || `New Zone ${cleaningZones.length + 1}`)
  const [description, setDescription] = useState(zone?.properties?.description || '')
  const [tempDisabled, setTempDisabled] = useState(false)

  const changeZoneType = useCallback((zoneType: MISSION_ZONE_TYPES) => {
    setSelectedZoneType(zoneType)
  }, [])

  const onSave = () => {
    onSaveClicked(name, description, selectedZoneType)
    setTempDisabled(true)
    setTimeout(() => {
      setTempDisabled(false)
    }, 3000)
  }

  const onCancelClicked = () => {
    cancelEditingZone()
  }

  const savingProgressInWork = useMemo(() => {
    return savingProgress === PROGRESS_TYPES.WORK
  }, [savingProgress])

  return (
    <div className={style.root}>
      <div className={style.content}>
        <div className="text-14-400">{t('zone_details.select_type')}</div>
        <div className={style.radioButtons}>
          <div className={style.radioButton}>
            <RadioButton
              checked={selectedZoneType === MISSION_ZONE_TYPES.CLEANING}
              label={t('zone_details.type_cleaning')}
              onChange={() => changeZoneType(MISSION_ZONE_TYPES.CLEANING)}
              disabled={!!zone}
            />
          </div>
          <div className={style.radioButton}>
            <RadioButton
              checked={selectedZoneType === MISSION_ZONE_TYPES.RESTRICT}
              label={t('zone_details.type_restrict')}
              onChange={() => changeZoneType(MISSION_ZONE_TYPES.RESTRICT)}
              disabled={!!zone}
            />
          </div>
        </div>
        <TextFieldDescribed
          className={style.textfield}
          description={t('zone_details.zone_name')}
          defaultValue={name}
          placeholder={t('zone_details.zone_name_placeholder')}
          onChange={e => setName(e.target.value)}
        />
        <TextFieldDescribed
          className={style.textfield}
          description={t('zone_details.zone_description')}
          defaultValue={description}
          placeholder={t('zone_details.zone_description_placeholder')}
          onChange={e => setDescription(e.target.value)}
        />
        {selectedZoneType === MISSION_ZONE_TYPES.CLEANING && (
          <div>
            <div className={clsx('text-14-400', style.subtitle)}>{t('zone_details.start_point')}</div>
            <LaunchPointSettings />
          </div>
        )}
      </div>
      <div className={style.buttons}>
        <Button.Outlined className={style.button} onClick={onCancelClicked} disabled={savingProgressInWork}>
          {t('common.cancel')}
        </Button.Outlined>
        <Button.Standart
          className={style.button}
          onClick={onSave}
          disabled={(!zone && !tempZone) || savingProgressInWork || tempDisabled}
          loading={savingProgressInWork}
        >
          {t('common.save')}
        </Button.Standart>
      </div>
    </div>
  )
}

export default ZoneDetail
