import unit_on_map from 'images/icons/unit_on_map.svg'
import robot_on_map from 'images/robot_on_map.png'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { useEffect, useState } from 'react'
import useMapStore from 'store/map'
import useUnitTelemetryStore from 'store/unitTelemetry'
import WebsocketManager, { SOCKET_MESSAGES } from 'utils/WebsocketManager'

interface Props {
  point?: {
    type?: 'Point'
    coordinates?: Array<number>
  } | null
  id?: string
}

const UnitLayer = ({ point, id }: Props) => {
  const map = useMapStore(s => s.map)
  const setTelemetry = useUnitTelemetryStore(s => s.setTelemetry)
  const clearTelemetry = useUnitTelemetryStore(s => s.clearTelemetry)

  const [robotLayer, setRobotLayer] = useState<VectorLayer<VectorSource> | null>(null)

  useEffect(() => {
    if (!id) return
    let websocketMessagesCount = 0

    const callback = (data: any) => {
      websocketMessagesCount += 1

      if (websocketMessagesCount % 3 === 0) {
        const { mission_id: missionId, mission_progress: missionProgress, time_left: timeLeft } = data
        setTelemetry({
          robots: data.robots.map((r: any) => ({
            id: r.id,
            name: r.name,
            batteryPower: r.battery_power,
            speed: r.speed,
            activeMode: r.active_mode,
            position: r.position,
            orientation: r.orientation,
            missionProgress: r.mission_progress,
            missionId: r.mission_id,
            missionName: r.mission_name,
          })),
          missionId,
          missionProgress,
          timeLeft,
          telemetry: {
            batteryCount: data.telemetry.battery_count,
            powerConsumption: data.telemetry.power_consumption,
            waterReserve: data.telemetry.water_reserve,
            statusCode: data.telemetry.status_code,
            slots: data.telemetry.slots,
          },
        })

        const robotFeatures = data.robots
          .filter((r: any) => {
            return r.position && r.position[0] !== null && r.position[1] !== null
          })
          .map((r: any) => {
            const feature = new Feature({
              geometry: new Point(fromLonLat([r.position.x, r.position.y])),
            })

            feature.setStyle(
              new Style({
                image: new Icon({
                  src: robot_on_map,
                  scale: 0.75,
                  rotation: r.orientation * (Math.PI / 180),
                }),
              })
            )

            return feature
          })

        robotLayer?.getSource()?.clear()
        robotLayer?.getSource()?.addFeatures(robotFeatures)
      }
    }

    WebsocketManager.sendMessage('{"type": "unit.subscribe", "unit_id": ' + id + '}')
    WebsocketManager.addEventListener(SOCKET_MESSAGES.UNIT_TELEMETRY, callback)
    return () => {
      WebsocketManager.removeEventListener(SOCKET_MESSAGES.UNIT_TELEMETRY, callback)
      WebsocketManager.disconnect()
      clearTelemetry()
    }
  }, [id, robotLayer])

  useEffect(() => {
    if (!point || !map || !point.coordinates) return
    const { coordinates = [] } = point

    const feature = new Feature({
      geometry: new Point(fromLonLat([coordinates[0], coordinates[1]])),
    })

    feature.setStyle(
      new Style({
        image: new Icon({
          src: unit_on_map,
          scale: 0.75,
        }),
      })
    )

    const layer = new VectorLayer({
      source: new VectorSource({
        features: [feature],
      }),
      zIndex: 2,
    })

    const robotLayer = new VectorLayer({
      source: new VectorSource({
        // features: [],
      }),
      zIndex: 3,
    })
    setRobotLayer(robotLayer)

    map.addLayer(layer)

    map.addLayer(robotLayer)

    map.getView().setCenter(fromLonLat([coordinates[0], coordinates[1]]))
    map.getView().setZoom(18)

    return () => {
      map.removeLayer(layer)
      map.removeLayer(robotLayer)
    }
  }, [point, map])

  return null
}

export default UnitLayer
