import Feature from 'ol/Feature'
import { asArray } from 'ol/color'
import { Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { useEffect, useState } from 'react'
import useMapStore from 'store/map'
import { IRestrictZone, IVisualizationSettings } from 'utils/interfaces'

interface Props {
  restrictedZones: IRestrictZone[]
  visualSettings: IVisualizationSettings
}

function RestrictedZonesLayer({ restrictedZones, visualSettings }: Props) {
  const map = useMapStore(s => s.map)
  const [layer, setLayer] = useState<VectorLayer<VectorSource<Feature<Polygon>>> | null>(null)

  useEffect(() => {
    if (!layer) return
    layer.getSource()?.clear()
    layer.getSource()?.addFeatures(
      restrictedZones.map(zone => {
        const feature = new Feature({
          // @ts-ignore
          geometry: new Polygon(zone.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
          ...zone.properties,
        })
        return feature
      })
    )
  }, [restrictedZones, layer])

  useEffect(() => {
    if (!map) return

    const fillColor = asArray(visualSettings.restrict_fill_color)
    fillColor[3] = visualSettings.restrict_opacity

    const layer = new VectorLayer({
      source: new VectorSource({
        features: restrictedZones.map(zone => {
          const feature = new Feature({
            // @ts-ignore
            geometry: new Polygon(zone.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
            ...zone.properties,
          })
          return feature
        }),
      }),
      properties: {
        name: 'Restricted Zones',
        showInLayerList: true,
      },
      zIndex: 3,
      style: {
        'fill-color': fillColor,
        'stroke-color': visualSettings.restrict_border_color,
        'stroke-width': visualSettings.restrict_width,
      },
    })
    map.addLayer(layer)
    setLayer(layer)

    return () => {
      map.removeLayer(layer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])
  useEffect(() => {
    const fillColor = asArray(visualSettings.restrict_fill_color)
    fillColor[3] = visualSettings.restrict_opacity

    layer?.setStyle({
      'fill-color': fillColor,
      'stroke-color': visualSettings.restrict_border_color,
      'stroke-width': visualSettings.restrict_width,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualSettings])
  return null
}

export default RestrictedZonesLayer
