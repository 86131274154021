import { Point } from 'geojson'
import UnitOnMapImage from 'images/unit_on_map.png'
import { Map, View } from 'ol'
import Feature from 'ol/Feature'
import { Point as OlPoint } from 'ol/geom'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat, toLonLat } from 'ol/proj'
import OSM from 'ol/source/OSM'
import VectorSource from 'ol/source/Vector'
import Icon from 'ol/style/Icon'
import Style from 'ol/style/Style'
import { useEffect, useRef, useState } from 'react'

import './ol.css'

interface Props {
  units: Point[]
  onPointChanged: (point: Point) => void
}

function UnitMap({ units, onPointChanged }: Props) {
  const [unitLayer, setUnitLayer] = useState<VectorLayer<VectorSource<Feature<OlPoint>>> | null>(null)
  const [map, setMap] = useState<Map | null>(null)

  const mapRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    if (!unitLayer) return

    unitLayer?.getSource()?.clear()
    unitLayer?.getSource()?.addFeatures(createUnitFeatures())
    zoomToUnit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [units])

  useEffect(() => {
    let map: Map | null = new Map({
      layers: [],
      view: new View({
        center: [0, 0],
        zoom: 2,
      }),
    })
    if (mapRef.current) map.setTarget(mapRef.current)

    const baseLayer = new TileLayer({
      source: new OSM(),
    })

    const layer = new VectorLayer({
      source: new VectorSource({
        features: createUnitFeatures(),
      }),
    })

    map.addLayer(baseLayer)
    map.addLayer(layer)
    setUnitLayer(layer)

    zoomToUnit()

    map.on('singleclick', e => {
      const coords = map?.getCoordinateFromPixel(e.pixel)
      if (!coords) return

      onPointChanged({
        type: 'Point',
        coordinates: toLonLat(coords),
      })
    })

    setMap(map)
    return () => {
      map?.setTarget(undefined)
      map = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createUnitFeatures = () => {
    return units?.map(unitPoint => {
      const feature = new Feature({
        geometry: new OlPoint(fromLonLat(unitPoint.coordinates)),
      })
      feature.setStyle(
        new Style({
          image: new Icon({
            src: UnitOnMapImage,
            // scale: 21,
          }),
        })
      )
      return feature
    })
  }
  const zoomToUnit = () => {
    if (!units[0] || !map) return
    map.getView().setCenter(fromLonLat(units[0].coordinates))
    map.getView().setZoom(18)
  }

  return <div ref={mapRef} style={{ width: '100%', height: '100%' }}></div>
}

export default UnitMap
