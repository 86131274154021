import { MainApi } from 'api/MainApi'
import useZoomToPolygon from 'hooks/useZoomToPolygon'
import { Polygon } from 'ol/geom'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { TRANSFER_TYPE } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

import CommonRouteItem from './CommonRouteItem/CommonRouteItem'

const MissionPointTransferItem = ({ type, hideActions }: { type: TRANSFER_TYPE; hideActions?: boolean }) => {
  const {
    setHighlightedFeature,
    editingTransfer,
    missionPoints,
    deleteMissionTransfer,
    setChangeEditingTransfer,
    cancelEditingTransfer,
    patchMissionTransfer,
  } = useMissionStore(
    useShallow(state => ({
      setHighlightedFeature: state.setHighlightedFeature,
      editingTransfer: state.editingTransfer,
      missionPoints: state.missionPoints,
      deleteMissionTransfer: state.deleteMissionTransfer,
      setChangeEditingTransfer: state.setChangeEditingTransfer,
      cancelEditingTransfer: state.cancelEditingTransfer,
      patchMissionTransfer: state.patchMissionTransfer,
    }))
  )
  const { t } = useTranslation()
  const { zoomToPolygon } = useZoomToPolygon()

  const missionRoute = useMemo(() => {
    return type === TRANSFER_TYPE.START
      ? missionPoints.routes.find(route => route.properties.type === TRANSFER_TYPE.START)
      : missionPoints.routes.find(route => route.properties.type === TRANSFER_TYPE.FINISH)
  }, [missionPoints.routes, type])

  const onClick = () => {
    if (!missionRoute) return

    setHighlightedFeature(missionRoute)
    const polygon = new Polygon([missionRoute.geometry.coordinates]).transform('EPSG:4326', 'EPSG:3857')
    zoomToPolygon(polygon as Polygon)
  }

  const onEditClicked = () => {
    if (missionRoute) {
      setChangeEditingTransfer(missionRoute)
    }
  }

  const onRemoveClicked = () => {
    if (missionRoute?.properties.id) {
      deleteMissionTransfer(missionRoute?.properties.id)
    }
  }

  const onCancelEditingClicked = (e: any) => {
    e.stopPropagation()
    cancelEditingTransfer()
  }

  const onSaveClicked = (e: any) => {
    e.stopPropagation()
    patchMissionTransfer()
  }

  const text = useMemo(() => {
    switch (type) {
      case TRANSFER_TYPE.START:
        return t('view_zones.start_transfer')
      case TRANSFER_TYPE.FINISH:
        return t('view_zones.finish_transfer')
    }
  }, [t, type])

  const editing = useMemo(() => {
    return editingTransfer === missionRoute
  }, [editingTransfer, missionRoute])

  const onCalculateClicked = async () => {
    try {
      if (missionRoute?.properties.id) {
        await MainApi.startCalculatingMissionPointRoute(missionRoute?.properties.id)
      }
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(error)
    }
  }

  return missionRoute?.id ? (
    <CommonRouteItem
      id={missionRoute.id}
      text={text}
      itemType={type}
      editing={editing}
      status={missionRoute.properties.status}
      length={missionRoute.properties.length}
      onClick={onClick}
      onEdit={onEditClicked}
      onCancel={onCancelEditingClicked}
      onRemove={onRemoveClicked}
      onSave={onSaveClicked}
      hideActions={hideActions}
      onCalculate={onCalculateClicked}
    />
  ) : null
}

export default MissionPointTransferItem
