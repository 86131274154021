import { MainApi } from 'api/MainApi'
import useDeletedTransfer from 'hooks/mission/useDeletedTransfer'
import useZoomToPolygon from 'hooks/useZoomToPolygon'
import { Polygon } from 'ol/geom'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useMissionStore from 'store/mission'
import { TRANSFER_TYPE } from 'utils/constants'
import { useShallow } from 'zustand/react/shallow'

import CommonRouteItem from './CommonRouteItem/CommonRouteItem'

const TransferRouteItem = ({
  type,
  zoneIdToTransfer,
  hideActions,
}: {
  type: TRANSFER_TYPE
  zoneIdToTransfer?: number
  hideActions?: boolean
}) => {
  const {
    setHighlightedFeature,
    transferRoutes,
    editingTransfer,
    setChangeEditingTransfer,
    cancelEditingTransfer,
    editTransfer,
    deleteTransfer,
  } = useMissionStore(
    useShallow(state => ({
      setHighlightedFeature: state.setHighlightedFeature,
      transferRoutes: state.transferRoutes,
      editingTransfer: state.editingTransfer,
      setChangeEditingTransfer: state.setChangeEditingTransfer,
      cancelEditingTransfer: state.cancelEditingTransfer,
      editTransfer: state.editTransfer,
      deleteTransfer: state.deleteTransfer,
    }))
  )
  const { t } = useTranslation()
  const { zoomToPolygon } = useZoomToPolygon()
  const { mutateAsync: setDeletedTransfer } = useDeletedTransfer()

  const transferRoute = useMemo(() => {
    const route = transferRoutes.find((route: any) => route.properties.zone_id_to === zoneIdToTransfer)
    return route
  }, [transferRoutes, zoneIdToTransfer])

  const onTransferRouteClicked = () => {
    if (!transferRoute) return

    setHighlightedFeature(transferRoute)
    const polygon = new Polygon([transferRoute.geometry.coordinates]).transform('EPSG:4326', 'EPSG:3857')
    zoomToPolygon(polygon as Polygon)
  }

  const onEditRouteClicked = () => {
    if (transferRoute) {
      setChangeEditingTransfer(transferRoute)
    }
  }

  const onRemoveRouteClicked = () => {
    if (transferRoute?.properties.id) {
      setDeletedTransfer(transferRoute?.properties.id).then(() => {
        deleteTransfer(transferRoute?.properties.id)
      })
    }
  }

  const onCancelEditingClicked = (e: any) => {
    e.stopPropagation()
    cancelEditingTransfer()
  }

  const onSaveClicked = (e: any) => {
    e.stopPropagation()
    editTransfer()
  }

  const onCalculateClicked = async () => {
    try {
      if (transferRoute?.properties.id) {
        await MainApi.startCalculatingTransferRoute(transferRoute?.properties.id)
      }
    } catch (error) {
      /* eslint-disable-next-line no-console */
      console.log(error)
    }
  }

  const text = useMemo(() => {
    switch (type) {
      case TRANSFER_TYPE.START:
        return t('view_zones.transfer_route_auto_start')
      case TRANSFER_TYPE.MIDDLE:
        return t('view_zones.transfer_route_auto')
      case TRANSFER_TYPE.FINISH:
        return t('view_zones.transfer_route_auto_finish')
      default:
        return t('view_zones.transfer_route_auto')
    }
  }, [t, type])

  const editing = useMemo(() => {
    return editingTransfer === transferRoute
  }, [editingTransfer, transferRoute])

  return (
    <>
      {transferRoute?.id ? (
        <CommonRouteItem
          id={transferRoute.id}
          text={text}
          itemType={type}
          editing={editing}
          status={transferRoute.properties.status}
          length={transferRoute.properties.length}
          onClick={onTransferRouteClicked}
          onEdit={onEditRouteClicked}
          onCancel={onCancelEditingClicked}
          onRemove={onRemoveRouteClicked}
          onSave={onSaveClicked}
          hideActions={hideActions}
          onCalculate={onCalculateClicked}
        />
      ) : null}
    </>
  )
}

export default TransferRouteItem
