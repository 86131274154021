import useAllCleaningRoutes from 'hooks/useAllCleaningRoutes'
import useAllCleaningZones from 'hooks/useAllCleaningZones'
import Feature from 'ol/Feature'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import Overlay from 'ol/Overlay'
import { asArray } from 'ol/color'
import { LineString, Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import useMapStore from 'store/map'

import CleaningZonePopup from './popup/CleaningZonePopup'

import styles from './popup/RobotsLayerPopup.module.css'

interface Popup {
  id?: number
  name?: string
  missionName?: string
  cleaned?: number
  robots?: { id: number; name: string }[]
}

interface Props {
  visible?: boolean
}

function AllCleaningZonesLayer({ visible = true }: Props) {
  const { data: cleaningZones = [] } = useAllCleaningZones()
  const { data: cleaningRoutes = [] } = useAllCleaningRoutes()

  const map = useMapStore(s => s.map)

  const [layer, setLayer] = useState<VectorLayer<VectorSource<Feature<Polygon>>> | null>(null)
  const [routesLayer, setRoutesLayer] = useState<VectorLayer<VectorSource<Feature<LineString>>> | null>(null)
  const [popupData, setPopupData] = useState<Popup>({})
  const popupRef = useRef<any>(null)

  const handleClosePopupClick = () => {
    popupRef.current.style.display = 'none'
  }

  useEffect(() => {
    if (!layer) return
    layer.getSource()?.clear()
    layer.getSource()?.addFeatures(
      cleaningZones.map(zone => {
        const feature = new Feature({
          // @ts-ignore
          geometry: new Polygon(zone.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
          id: zone.id,
          name: zone.name,
          missionName: zone.mission_name,
          cleaned: zone.cleaning_route_cleaned_percentage,
          robots: zone.robots,
          layerType: 'cleaningZone',
        })
        return feature
      })
    )
  }, [cleaningZones, layer])

  useEffect(() => {
    if (!routesLayer) return
    routesLayer.getSource()?.clear()

    routesLayer.getSource()?.addFeatures(
      cleaningRoutes
        .filter(r => r.cleaning_route_geometry && r.cleaning_route_geometry.coordinates)
        .map(
          route =>
            new Feature({
              geometry: new LineString(route.cleaning_route_geometry!.coordinates![0]).transform(
                'EPSG:4326',
                'EPSG:3857'
              ),
            })
        )
    )
  }, [cleaningRoutes, routesLayer])

  useEffect(() => {
    if (!map) return

    const fillColor = asArray([211, 211, 211])
    fillColor[3] = 0.2

    const layer = new VectorLayer({
      source: new VectorSource({
        features: cleaningZones.map(zone => {
          const feature = new Feature({
            // @ts-ignore
            geometry: new Polygon(zone.geometry.coordinates).transform('EPSG:4326', 'EPSG:3857'),
            id: zone.id,
            name: zone.name,
            missionName: zone.mission_name,
            cleaned: zone.cleaning_route_cleaned_percentage,
            robots: zone.robots,
          })
          return feature
        }),
      }),
      properties: {
        name: 'All Cleaning Zones',
        showInLayerList: true,
      },
      visible: visible,
      zIndex: 3,
      style: new Style({
        fill: new Fill({
          color: fillColor,
        }),
        stroke: new Stroke({
          color: '#000000',
          width: 2,
        }),
      }),
    })

    map.addLayer(layer)
    setLayer(layer)

    const routesLayer = new VectorLayer({
      source: new VectorSource({
        features: cleaningRoutes
          .filter(r => r.cleaning_route_geometry && r.cleaning_route_geometry.coordinates)
          .map(route => {
            const feature = new Feature({
              // @ts-ignore
              geometry: new LineString(route.cleaning_route_geometry!.coordinates![0]).transform(
                'EPSG:4326',
                'EPSG:3857'
              ),
            })
            return feature
          }),
      }),
      properties: {
        name: 'Cleaning routes',
        showInLayerList: true,
      },
      visible: false,
      zIndex: 1,
      style: new Style({
        fill: new Fill({
          color: 'white',
        }),
        stroke: new Stroke({
          color: '#63605f',
          width: 1,
        }),
      }),
    })

    map.addLayer(routesLayer)
    setRoutesLayer(routesLayer)

    const clickHandler = (e: MapBrowserEvent<any>) => {
      const feature = map.forEachFeatureAtPixel(e.pixel, f => f)
      if (!feature) {
        popupRef.current.style.display = 'none'
        return setPopupData({})
      }

      const { id, missionName, name, cleaned, robots, layerType } = feature.getProperties()
      if (layerType !== 'cleaningZone') return

      setPopupData({ id, name, missionName, cleaned, robots })
      popupRef.current.style.display = 'block'
      popupOverlay.setPosition(e.coordinate)
    }

    map.on('singleclick', clickHandler)

    const popupOverlay = new Overlay({
      element: popupRef.current,
    })
    map.addOverlay(popupOverlay)

    return () => {
      map.removeLayer(layer)
      map.removeLayer(routesLayer)
      map.un('singleclick', clickHandler)
      map.removeOverlay(popupOverlay)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  return (
    <div ref={popupRef} className={styles['ol-popup']} style={{ display: 'none' }}>
      <button className={styles['ol-popup-closer']} onClick={handleClosePopupClick}>
        <MdClose size={20} />
      </button>
      <CleaningZonePopup
        name={popupData.name}
        missionName={popupData.missionName}
        cleaned={popupData.cleaned}
        robots={popupData.robots}
      />
    </div>
  )
}

export default AllCleaningZonesLayer
