import Feature from 'ol/Feature'
import { LineString, Point } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import { fromLonLat } from 'ol/proj'
import VectorSource from 'ol/source/Vector'
import CircleStyle from 'ol/style/Circle.js'
import Fill from 'ol/style/Fill'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { useEffect, useState } from 'react'
import useMapStore from 'store/map'
import { ILaunchPoint, IRoute, IVisualizationSettings } from 'utils/interfaces'

interface Props {
  cleaningRoutes: IRoute[]
  launchPoints: ILaunchPoint[]
  visualSettings: IVisualizationSettings
}

function CleaningRoutesLayer({ cleaningRoutes, launchPoints, visualSettings }: Props) {
  const map = useMapStore(s => s.map)
  const [layer, setLayer] = useState<VectorLayer<VectorSource<Feature<LineString> | Feature<Point>>> | null>(null)

  useEffect(() => {
    layer?.getSource()?.clear()
    const features = getFeatures()
    layer?.getSource()?.addFeatures(features)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleaningRoutes, layer, launchPoints])

  useEffect(() => {
    const source = new VectorSource({
      features: getFeatures(),
    })

    const layer = new VectorLayer({
      source,
      properties: {
        name: 'Cleaning Routes',
        showInLayerList: true,
      },
      zIndex: 2,
      style: feature => {
        return new Style(
          feature?.getGeometry()?.getType() === 'Point'
            ? {
                image: new CircleStyle({
                  radius: 6,
                  fill: new Fill({
                    color: (feature.getId() as string)?.includes('launch_point') ? 'white' : '#9747FF',
                  }),
                  stroke: new Stroke({
                    color: (feature.getId() as string)?.includes('launch_point') ? '#9747FF' : 'white',
                    width: 2,
                  }),
                }),
              }
            : {
                stroke: new Stroke({
                  color: visualSettings.cleaning_route_color,
                  width: visualSettings.cleaning_route_width,
                }),
              }
        )
      },
      opacity: visualSettings.cleaning_route_opacity,
    })
    map?.addLayer(layer)
    setLayer(layer)
    return () => {
      map?.removeLayer(layer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map])

  const getFeatures = () => {
    const features = [
      ...cleaningRoutes.map(route => {
        const feature = new Feature({
          // @ts-ignore
          geometry: new LineString(route.geometry.coordinates[0]).transform('EPSG:4326', 'EPSG:3857'),
          ...route.properties,
        })
        feature.setId(route.id)
        return feature
      }),
      ...cleaningRoutes.map(route => {
        const lastLineIndex = route.geometry.coordinates.length - 1
        const lastCoordIndex = route.geometry.coordinates[lastLineIndex].length - 1
        const feature = new Feature({
          geometry: new Point(fromLonLat(route.geometry.coordinates[lastLineIndex][lastCoordIndex])),
        })
        feature.setId(route.id + '_finish_point')
        return feature
      }),
      ...launchPoints.map(point => {
        const feature = new Feature({
          geometry: new Point(fromLonLat([point.geometry.coordinates[0], point.geometry.coordinates[1]])),
        })
        feature.setId(point.id)
        return feature
      }),
    ]
    cleaningRoutes.forEach(cleaningRoute => {
      const launchPointExisting = launchPoints.find(
        launchPoint => launchPoint.properties?.zone_id === cleaningRoute.properties?.zone_id
      )
      if (!launchPointExisting) {
        const feature = new Feature({
          geometry: new Point(fromLonLat(cleaningRoute.geometry.coordinates[0][0])),
        })
        feature.setId(cleaningRoute.id + '_launch_point')
        features.push(feature)
      }
    })
    return features
  }

  useEffect(() => {
    layer?.setStyle(feature => {
      return new Style(
        feature?.getGeometry()?.getType() === 'Point'
          ? {
              image: new CircleStyle({
                radius: 6,
                fill: new Fill({
                  color: (feature.getId() as string)?.includes('launch_point') ? 'white' : '#9747FF',
                }),
                stroke: new Stroke({
                  color: (feature.getId() as string)?.includes('launch_point') ? '#9747FF' : 'white',
                  width: 2,
                }),
              }),
            }
          : {
              stroke: new Stroke({
                color: visualSettings.cleaning_route_color,
                width: visualSettings.cleaning_route_width,
              }),
            }
      )
    })
    layer?.setOpacity(visualSettings.cleaning_route_opacity)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visualSettings])

  return null
}

export default CleaningRoutesLayer
