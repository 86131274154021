import OverflownText from '../OverflownText/OverflownText'
import { Box, Button, CircularProgress, Grid, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material'
// import Loader from 'components/Loader/Loader'
import SelectMissionModal from 'components/Robot/RobotMission/SelectMissionModal'
import useMission from 'hooks/useMission'
import useRobot from 'hooks/useRobot'
import useSelectMission from 'hooks/useSelectMissions'
import useSetRobotCommand from 'hooks/useSetRobotCommand'
import useSetRobotMode from 'hooks/useSetRobotMode'
import useUploadMission from 'hooks/useUploadMission'
import { ReactComponent as AttentionIcon } from 'images/icons/attention.svg'
import { ReactComponent as QuestionMarkIcon } from 'images/icons/faq.svg'
import { ReactComponent as HomeIcon } from 'images/icons/home.svg'
import { ReactComponent as PauseIcon } from 'images/icons/pause.svg'
import { ReactComponent as PlayIcon } from 'images/icons/play.svg'
import { ReactComponent as StopIcon } from 'images/icons/stop.svg'
import { ReactComponent as WarningIcon } from 'images/icons/warning.svg'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useTelemetryStore from 'store/robotTelemetry'
import { MISSION_JOB_STATUSES, PATH, ROBOT_COMMANDS } from 'utils/constants'
import { IMissionInfo } from 'utils/interfaces'

import style from './RobotControlBoard.module.css'

// active mode 2 - intelligent mode, 0 - standby mode
// active command 1 - start,  2 - stop, 3 - pause,

const RobotControlBoard = () => {
  const navigate = useNavigate()
  const { data: robot } = useRobot()
  const { data: mission } = useMission(robot?.job?.missionId)
  const { mutateAsync: selectMission, error: selectMissionError, isPending: selectMissionLoading } = useSelectMission()
  const {
    mutateAsync: uploadMission,
    error: uploadMissionError,
    isPending: uploadMissionLoading,
  } = useUploadMission(robot?.id as number)
  const { mutate: setRobotMode, isPending: modeChangeLoading } = useSetRobotMode()
  const { mutate: setRobotCommand, isPending: commandChangeLoading } = useSetRobotCommand()
  const telemetry = useTelemetryStore(s => s.telemetry)
  const uploadingState = telemetry.missionStatus
  const [showModal, setShowModal] = useState(false)

  const handleMissionSelected = async (mission: IMissionInfo | undefined) => {
    setShowModal(false)

    if (mission && robot?.id) {
      const misisonJob = await selectMission({ missionId: mission.id, robotId: robot.id })

      if (misisonJob && misisonJob.id) {
        await uploadMission(misisonJob.id)
      }
    }
  }

  const handleModeClicked = (mode: number) => {
    if (!robot?.id || mode === telemetry?.activeMode) return
    setRobotMode({ id: robot.id, mode })
  }

  const handleManualClicked = () => {
    robot?.id && navigate(`${PATH.MANUAL_MODE}/${robot.id}`)
  }

  const handleCommandClicked = (command: ROBOT_COMMANDS) => {
    if (!robot?.id || command === telemetry?.activeCommand) return
    setRobotCommand({ id: robot?.id, command })
  }

  // if (!telemetry?.activeMode && telemetry?.activeMode !== 0) return <Loader />

  return (
    <Box
      bgcolor="info.main"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      borderRadius={2}
      className={style.wrapper}
    >
      <Stack className={style.cardMission} direction="row" justifyContent="space-between" padding={2} paddingBottom={0}>
        <Typography variant="headerRobot">
          Control board{' '}
          {(modeChangeLoading || telemetry?.activeModeIsLoading || telemetry?.activeCommandIsLoading) && (
            <CircularProgress color="secondary" size={18} />
          )}
          {!telemetry?.activeMode && telemetry?.activeMode !== 0 && <AttentionIcon />}
        </Typography>
        <Tooltip title="" placement="top">
          <IconButton size="small">
            <QuestionMarkIcon />
          </IconButton>
        </Tooltip>
      </Stack>
      <Box className={style.tabsWrapper} padding={1}>
        <Typography variant="subTitleTile">Mode</Typography>
        <Tabs value={telemetry?.activeMode ?? false} className="tabsMode white">
          <Tab
            value={0}
            label="Standby"
            onClick={() => handleModeClicked(0)}
            disabled={modeChangeLoading || telemetry?.activeModeIsLoading}
            className={style.tabMode}
          />
          <Tab
            value={2}
            label="Intelligent"
            onClick={() => handleModeClicked(2)}
            disabled={modeChangeLoading || telemetry?.activeModeIsLoading}
            className={style.tabMode}
          />
          <Tab value="manual" label="Manual" onClick={() => handleManualClicked()} className={style.tabMode} />
        </Tabs>
      </Box>

      <Grid className={style.gridMission} container spacing={1} padding={1}>
        <Grid item xs={6}>
          <Stack direction="column" alignItems="start" width="100%" textOverflow="ellipsis" overflow="hidden">
            <Typography variant="panelMode">
              Current mission
              {(uploadingState === MISSION_JOB_STATUSES.ERROR || selectMissionError || uploadMissionError) && (
                <WarningIcon />
              )}
              {robot?.maxCoverageArea &&
                mission?.mission.cleaningArea &&
                robot?.maxCoverageArea < mission?.mission.cleaningArea && (
                  <Tooltip title="Cleaning area exceeds robot's coverage area">
                    <WarningIcon />
                  </Tooltip>
                )}
            </Typography>
            <OverflownText placement="top" description={robot?.job?.missionName || 'Mission not selected'} />
          </Stack>
        </Grid>
        <Grid className={style.gridBtnMissionHandler} item xs={6}>
          <Button
            disabled={
              telemetry?.activeMode === 2 ||
              uploadingState === MISSION_JOB_STATUSES.CALCULATING ||
              selectMissionLoading ||
              uploadMissionLoading
            }
            onClick={() => setShowModal(true)}
            variant="outlined"
            color="secondary"
            fullWidth
            className={style.btnMissionSelect}
          >
            {uploadingState === MISSION_JOB_STATUSES.CALCULATING || selectMissionLoading || uploadMissionLoading ? (
              <>
                Loading... <CircularProgress size={15} color="secondary" />
              </>
            ) : (
              'Select mission'
            )}
          </Button>
        </Grid>

        <Grid className={style.gridBtnMissionHandler} item xs={6}>
          <Button
            onClick={() =>
              handleCommandClicked(
                telemetry?.activeCommand === ROBOT_COMMANDS.START ? ROBOT_COMMANDS.PAUSE : ROBOT_COMMANDS.START
              )
            }
            disabled={telemetry?.activeMode !== 2 || commandChangeLoading || telemetry?.activeCommandIsLoading}
            variant={telemetry?.activeCommand === ROBOT_COMMANDS.START ? 'outlined' : 'contained'}
            color={telemetry?.activeCommand === ROBOT_COMMANDS.START ? 'warning' : 'primary'}
            fullWidth
            sx={{
              ':disabled': {
                bgcolor: telemetry?.activeCommand === ROBOT_COMMANDS.START ? '#F47E2920' : '#9ADAA3',
                color: '#fff',
              },
              bgcolor: telemetry?.activeCommand === ROBOT_COMMANDS.START ? '#F47E2933' : '#41C04E',
            }}
            className={style.btnMissionHandler}
            endIcon={
              telemetry?.activeCommand === ROBOT_COMMANDS.START ? (
                <PauseIcon className={style.iconMussionHandler} />
              ) : (
                <PlayIcon className={style.iconMussionHandler} />
              )
            }
          >
            {telemetry?.activeCommand === ROBOT_COMMANDS.START ? 'Pause' : 'Run mission'}
          </Button>
        </Grid>
        <Grid className={style.gridBtnMissionHandler} item xs={6}>
          <Button
            onClick={() => handleCommandClicked(ROBOT_COMMANDS.STOP)}
            // disabled={telemetry?.activeMode !== 2 || commandChangeLoading || telemetry?.activeCommandIsLoading}
            variant="contained"
            fullWidth
            color={telemetry?.activeCommand === ROBOT_COMMANDS.STOP ? 'secondary' : 'error'}
            sx={
              telemetry?.activeCommand === ROBOT_COMMANDS.STOP
                ? { bgcolor: '#424040', color: '#fff' }
                : {
                    bgcolor: '#D56464',
                    color: '#fff',
                  }
            }
            className={style.btnMissionHandler}
            endIcon={
              telemetry?.activeCommand === ROBOT_COMMANDS.STOP ? (
                <HomeIcon />
              ) : (
                <StopIcon className={style.iconMussionHandler} />
              )
            }
          >
            {telemetry?.activeCommand === ROBOT_COMMANDS.STOP ? 'Return to unit' : 'Stop'}
          </Button>
        </Grid>
      </Grid>
      <SelectMissionModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirmClick={handleMissionSelected}
      />
    </Box>
  )
}

export default RobotControlBoard
